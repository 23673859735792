import service from '@/libs/axios.js'

export const recApiGet = (params) => service.get('http://www.baidu.com', { params: params })
export const login = (params) => service.post('/api/sysAuth/loginApp', params)
export const recApiPost = (params) => service.post('http://www.baidu.com', params)
// 用户列表
export const sysUserList = (params) => service.post('api/sysUser/GetSysUserPage', params)
export const sysUserUpdate = (params) => service.post('/api/sysUser/update', params)
export const sysUserAdd = (params) => service.post('/api/sysUser/add', params)
export const sysUserDelete = (params) => service.post('/api/sysUser/delete', params)
export const sysUserDetail = (params) => service.post('/api/sysUser/detail', params)
export const getUserInfo = (params) => service.get('/api/sysAuth/userInfo', { params: params })
export const ChangePwdByUser = (params) => service.post('/api/sysUser/ChangePwdByUser', params)
export const ChangePwd = (params) => service.post('/api/sysUser/ChangePwd', params)
export const AuthDictList = (params) => service.post('/api/AuthDict/List', params)
export const BuiltInAccountLogin = (params) => service.post('/api/sysAuth/BuiltInAccountLogin', params)


//机构
export const sysOrgList = (params) => service.get('/api/sysOrg/list',  { params: params })
export const sysOrgListById = (params) => service.get('/api/sysOrg/ListById',  { params: params })
export const sysOrgListAll = (params) => service.post('/api/sysOrg/GetListAll', params)
// 新增角色AddBusRole
export const roleList = (params) => service.get('/api/sysRole/list', params)
export const roleAdd = (params) => service.post('/api/sysRole/AddBusRole',  params)
export const UpdateBusRole = (params) => service.post('/api/sysRole/UpdateBusRole',  params)
export const deleteBusRole = (params) => service.post('/api/sysRole/delete',  params)
//职务
export const posList = (params) => service.get('/api/sysPos/list', params)
//菜单
export const treeList = (params) => service.get('/api/businessMenu/treeList', params)
export const LoginMenu = (params) => service.get('/api/businessMenu/LoginMenu', params)
export const ownMenuList = (params) => service.get('/api/sysRole/ownMenuList', { params: params })
export const addMenu = (params) => service.post('/api/businessMenu/add',  params) //新增菜单

//更新角色菜单
export const RoleMenuUpdate = (params) => service.post('/api/sysRole/UpdateBusRole', params)

//api/sysDictData/dataListAll?code=article_type 获取文章类型
export const dataListAll = (params) => service.get('/api/sysDictData/dataListAll', { params: params })
//api/sysDictData/dataListAll?code=article_type 更新文章
export const articleUpdate = (params) => service.post('/api/article/update', params)
export const articleList = (params) => service.post('/api/article/page', params)
export const articleAdd = (params) => service.post('/api/article/add', params)
export const articleDetail = (params) => service.get('/api/article/detail', { params: params })
export const articleDelete = (params) => service.post('/api/article/delete', params )
//文件
//文件类型字典
export const fileDict = (params) => service.get('/api/sysDictData/dataListAll', { params: params })
//分页列表
var fileList;
 if(JSON.parse(localStorage.getItem('showRole'))){
// export const fileList = (params) => service.post('/api/fileInfo/page', params)
      fileList = (params) => service.post('/api/fileInfo/page', params)
}else{
      fileList = (params) => service.post('/api/fileInfo/page', params)
 }
export  {fileList}
export const addFile = (params) => service.post('/api/fileInfo/add', params)
export const addUpdate = (params) => service.post('/api/fileInfo/update', params)
export const fileDelete = (params) => service.post('/api/fileInfo/delete', params)
export const fileDetail = (params) => service.get('/api/fileInfo/detail', { params: params })
export const fileApplyDel = (params) => service.get('/api/fileInfo/ApplyDel', { params: params })//删除申请
export const fileApplyUpdate = (params) => service.get('/api/fileInfo/ApplyUpdate', { params: params })//编辑申请
export const fileApplyBorrowing = (params) => service.get('/api/fileInfo/ApplyBorrowing', { params: params })//借阅申请 ApplyBorrowing
export const uploadFile = (params) => service.post('/api/sysFile/uploadFile', params)
export const flowPage = (params) => service.post('/api/fileInfo/flowPage', params)
export const Audit = (params) => service.post('/api/fileInfo/Audit', params)
export const GetBorrowingListByOrg = (params) => service.post('/api/fileInfo/GetBorrowingListByOrg', params) // 可借阅文件列表 /api/fileInfo/GetBorrowingListByOrg
export const GetBorrowedListByUser = (params) => service.post('/api/fileInfo/GetBorrowedListByUser', params) // 已经借阅文件列表 /api/fileInfo/GetBorrowedListByUser



//监控 /api/monitor/list
export const monitorList = (params) => service.post('/api/monitor/list',params) //万能机钢筋
//警告部分
export const alarmAdd = (params) => service.post('/api/alarmInfo/add', params) //新增
export const alarmDetail = (params) => service.get('/api/alarmInfo/detail', { params: params }) //新增
export const alarmUrl = (params) => service.get('/api/openAPI/GetEncryptedOBSFileUrls', { params: params }) //新增
export const alarmUpdate = (params) => service.post('/api/alarmInfo/update', params) //新增
export const alarmList = (params) => service.post('/api/alarmInfo/page', params) //列表
//激光雷达 /api/radarLaserPointCloud/page
export const radarAdd = (params) => service.post('/api/radarLaserPointCloud/add', params) //新增
export const radarDetail = (params) => service.get('/api/radarLaserPointCloud/detail', { params: params }) //新增
export const radarGetBD = (params) => service.get('/api/radarLaserPointCloud/GetBD', { params: params }) //获取激光标段  /api/radarLaserPointCloud/GetBD
export const radarUpdate = (params) => service.post('/api/radarLaserPointCloud/update', params) //新增
export const radarDelete = (params) => service.post('/api/radarLaserPointCloud/delete', params) //新增
export const radarList = (params) => service.post('/api/radarLaserPointCloud/page', params) //列表

//获取通讯录
export const addressBook = (params) => service.post('/api/addressBook/list', params) //列表
//标段部分
export const bidAdd = (params) => service.post('/api/bidSection/add', params) //新增
export const bidDetail = (params) => service.get('/api/bidSection/detail', { params: params }) //新增
export const bidUpdate = (params) => service.post('/api/bidSection/update', params) //新增
export const bidDelete = (params) => service.post('/api/bidSection/delete', params) //新增
export const bidList = (params) => service.post('/api/bidSection/page', params) //列表
export const bids = (params) => service.post('/api/bidSection/list', params) //列表
//招采  /api/procurement/page
export const procurementAdd = (params) => service.post('/api/procurement/add', params) //新增
export const procurementDetail = (params) => service.get('/api/procurement/detail', { params: params }) //新增
export const procurementUpdate = (params) => service.post('/api/procurement/update', params) //新增
export const procurementPage = (params) => service.post('/api/procurement/page', params) //列表
export const procurementDelete = (params) => service.post('/api/procurement/delete', params) //删除
//质量管理实验室数据
export const pressMachineList = (params) => service.get('/api/IoTPressMachine/list', { params: params }) //压力机
// export const cementList = (params) => service.get('/api/IoTUniversalMachineCement/list', { params: params }) //万能机水泥
// export const rebarList = (params) => service.get('/api/IoTUniversalMachineRebar/list', { params: params }) //万能机钢筋
//质量关键控制点
export const controlAdd = (params) => service.post('/api/QualityCriticalControlPoints/add', params) //新增
export const controlDetail = (params) => service.get('/api/QualityCriticalControlPoints/detail', { params: params }) //详情
export const controlUpdate = (params) => service.post('/api/QualityCriticalControlPoints/update', params) //编辑
export const controlList = (params) => service.post('/api/QualityCriticalControlPoints/page', params) //编辑
export const controlDelete = (params) => service.post('/api/QualityCriticalControlPoints/delete', params) //编辑
export const controlImport = (params) => service.post('/api/QualityCriticalControlPoints/Import', params, {
    headers: { "Content-Type": "multipart/form-data" }
}) //导入

//风险点
export const risklAdd = (params) => service.post('/api/constructionOperationRisks/add', params) //新增
export const riskDetail = (params) => service.get('/api/constructionOperationRisks/detail', { params: params }) //详情
export const riskUpdate = (params) => service.post('/api/constructionOperationRisks/update', params) //编辑
export const riskList = (params) => service.post('/api/constructionOperationRisks/page', params) //编辑
export const riskDelete = (params) => service.post('/api/constructionOperationRisks/delete', params) //编辑
//本月风险要点
export const riskPointsAdd = (params) => service.post('/api/riskPoints/add', params) //新增
export const riskPointsDetail = (params) => service.get('/api/riskPoints/detail', { params: params }) //详情
export const riskPointsUpdate = (params) => service.post('/api/riskPoints/update', params) //编辑
export const riskPointsList = (params) => service.post('/api/riskPoints/page', params) //编辑
export const riskPointsDelete = (params) => service.post('/api/riskPoints/delete', params) //删除

export const riskPointsRoadAdd = (params) => service.post('/api/RiskPointsRoad/add', params) //新增
export const riskPointsRoadDetail = (params) => service.get('/api/RiskPointsRoad/detail', { params: params }) //详情
export const riskPointsRoadUpdate = (params) => service.post('/api/RiskPointsRoad/update', params) //编辑
export const riskPointsRoadList = (params) => service.post('/api/RiskPointsRoad/page', params) //编辑
export const riskPointsRoadDelete = (params) => service.post('/api/RiskPointsRoad/delete', params) //删除


export const riskImport = (params) => service.post('/api/RiskPoints/Import', params, {
    headers: { "Content-Type": "multipart/form-data" }
}) //导入

//科研
export const scientificAdd = (params) => service.post('/api/scientificResearch/add', params) //新增
export const scientificDetail = (params) => service.get('/api/scientificResearch/detail', { params: params }) //详情
export const scientificUpdate = (params) => service.post('/api/scientificResearch/update', params) //更新
export const scientificList = (params) => service.post('/api/scientificResearch/page', params) //编辑

//施工进度 /api/constructionProgress/audit

export const progressPage = (params) => service.post('/api/constructionProgress/page', params) //列表
export const progressGetOrg = (params) => service.get('/api/constructionProgress/getOrg', params) //获取标段
export const progressAudit = (params) => service.post('/api/constructionProgress/audit', params) //审核
export const progressAdd = (params) => service.post('/api/constructionProgress/add', params) //新增
export const progressDelete = (params) => service.post('/api/constructionProgress/delete', params) //删除
export const progressUpdate = (params) => service.post('/api/constructionProgress/update', params) //更新
export const progressExport = (params) => service.post('/api/constructionProgress/export', params) //导出
export const progressDetail = (params) => service.get('/api/constructionProgress/detail', { params: params }) //详情
export const progressList = (params) => service.get('/api/constructionProgress/list', { params: params }) //填报列表


//施工进度 /api/constructionProgressFilling/audit
export const progressFillPage = (params) => service.post('/api/constructionProgressFilling/page', params) //列表
export const progressFillAudit = (params) => service.post('/api/constructionProgressFilling/audit', params) //审核
export const progressFillAdd = (params) => service.post('/api/constructionProgressFilling/add', params) //新增
export const progressFillDelete = (params) => service.post('/api/constructionProgressFilling/delete', params) //删除
export const progressFillUpdate = (params) => service.post('/api/constructionProgressFilling/update', params) //更新
export const progressFillDetail = (params) => service.get('/api/constructionProgressFilling/detail', { params: params }) //详情
export const progressFillList = (params) => service.get('/api/constructionProgressFilling/list', { params: params }) //填报列表

//施工进度 /api/constructionProgressItem/audit
export const progressItemPage = (params) => service.post('/api/constructionProgressItem/page', params) //列表
export const progressItemAudit = (params) => service.post('/api/constructionProgressItem/audit', params) //审核
export const progressItemAdd = (params) => service.post('/api/constructionProgressItem/add', params) //新增
export const progressItemDelete = (params) => service.post('/api/constructionProgressItem/delete', params) //删除
export const progressItemUpdate = (params) => service.post('/api/constructionProgressItem/update', params) //更新
export const progressItemDetail = (params) => service.get('/api/constructionProgressItem/detail', { params: params }) //详情
export const progressItemList = (params) => service.post('/api/constructionProgressItem/list',params) //填报列表

//科研信息 /api/scientificResearch/page
export const sciencePage = (params) => service.post('/api/scientificResearch/page', params) //列表
export const scienceAdd = (params) => service.post('/api/scientificResearch/add', params) //新增
export const scienceDelete = (params) => service.post('/api/scientificResearch/delete', params) //删除
export const scienceUpdate = (params) => service.post('/api/scientificResearch/update', params) //更新
export const scienceDetail = (params) => service.get('/api/scientificResearch/detail', { params: params }) //详情

//实验室数据
export const instrumentList = (params) => service.post('/api/IoTReboundInstrument/page', params ) //回弹仪
export const instrumentRoomList = (params) => service.post('/api/IoTStandardMaintenanceRoom/page', params ) //标养室
export const protectList = (params) => service.post('/api/IoTReinforcementProtectionLayer/page',params ) //钢筋保护层
export const cementList = (params) => service.post('/api/IoTUniversalMachineCement/page', params ) //IoTUniversalMachineCement/list 万能机水泥
export const rebarList = (params) => service.post('/api/IoTUniversalMachineRebar/page', params) //IoTUniversalMachineRebar/list 万能机钢筋
export const pressList = (params) => service.post('/api/IoTPressMachine/page', params) //IoTPressMachine/list 压力机
export const IoTMixAsphaltList = (params) => service.post('/api/IoTMixAsphalt/page', params) //IoTPressMachine/list 沥青
export const IoTMixCementList = (params) => service.post('/api/IoTMixCement/page', params) //IoTPressMachine/list 水泥
//安全隐患填报
export const safeList = (params) => service.post('/api/SafetyHazards/page', params ) //隐患列表
export const safeAdd = (params) => service.post('/api/SafetyHazards/add', params ) //隐患新增
export const safeUpdate = (params) => service.post('/api/SafetyHazards/update', params ) //隐患编辑
export const safeDelete = (params) => service.post('/api/SafetyHazards/delete', params ) //隐患编辑
export const safeDetail = (params) => service.get('/api/SafetyHazards/detail', {params:params} ) //隐患编辑
//梁场专项
export const qualityList = (params) => service.post('/api/QualityControl/page', params ) //隐患列表
export const qualityAdd = (params) => service.post('/api/QualityControl/add', params ) //隐患新增
export const qualityUpdate = (params) => service.post('/api/QualityControl/update', params ) //隐患编辑
export const qualityDetail = (params) => service.get('/api/QualityControl/detail', {params:params} ) //隐患编辑
export const qualityDelete = (params) => service.post('/api/QualityControl/delete',params ) //隐患编辑


//月报 MonthlyConstructionPlan/add
export const monthList = (params) => service.post('/api/MonthlyConstructionPlan/page', params ) //列表
export const monthAdd = (params) => service.post('/api/MonthlyConstructionPlan/add', params ) //新增
export const monthUpdate = (params) => service.post('/api/MonthlyConstructionPlan/update', params ) //编辑
export const monthDetail = (params) => service.get('/api/MonthlyConstructionPlan/detail', {params:params} ) //详情
export const monthDelete = (params) => service.post('/api/MonthlyConstructionPlan/delete', params ) //详情
// 日报 DailyConstructionReport
export const dailyList = (params) => service.post('/api/DailyConstructionReport/page', params ) //列表
export const dailyAdd = (params) => service.post('/api/DailyConstructionReport/add', params ) //新增
export const dailyUpdate = (params) => service.post('/api/DailyConstructionReport/update', params ) //编辑
export const dailyDetail = (params) => service.get('/api/DailyConstructionReport/detail', {params:params} ) //详情

export const dailySave = (params) => service.post('/api/ConstructionFilling/Save', params ) //编辑
export const dailyAudit = (params) => service.post('/api/ConstructionFilling/Audit', params ) //编辑

export const monthSave = (params) => service.post('/api/MonthlyConstructionPlan/Save', params ) //编辑
export const monthAudit = (params) => service.post('/api/MonthlyConstructionPlan/Audit', params ) //编辑


export const yearPlanAdd = (params) => service.post('/api/AnnualDemandForBeamYard/add', params ) //编辑
export const yearPlanUpdate = (params) => service.post('/api/AnnualDemandForBeamYard/update', params ) //编辑
export const yearPlanPage = (params) => service.post('/api/AnnualDemandForBeamYard/page', params ) //编辑
export const yearPlanDetail = (params) => service.get('/api/AnnualDemandForBeamYard/detail', {params:params} ) //详情
export const yearPlanDelete = (params) => service.post('/api/AnnualDemandForBeamYard/delete', params ) //详情

//排岗信息 JobScheduling/add
export const JobAdd = (params) => service.post('/api/JobScheduling/add', params ) //编辑
export const JobUpdate = (params) => service.post('/api/JobScheduling/update', params ) //编辑
export const JobList = (params) => service.post('/api/JobScheduling/page', params ) //编辑
export const JobDetail = (params) => service.get('/api/JobScheduling/detail', {params:params} ) //详情
export const JobDelete = (params) => service.post('/api/JobScheduling/delete', params ) //详情

//汇总 MonthlyConstructionPlan/WorkAreaSummaryExport?Id=15681987004485
export const WorkAreaSummaryList = (params) => service.post('/api/MonthlyConstructionPlan/WorkAreaSummaryList',params ) //详情
export const AnnualSummaryList = (params) => service.post('/api/MonthlyConstructionPlan/AnnualSummaryList',params) //详情


//网格化管理 GridBased/add
export const GridBasedAdd = (params) => service.post('/api/GridBased/add', params ) //编辑
export const GridBasedUpdate = (params) => service.post('/api/GridBased/update', params ) //编辑
export const GridBasedList = (params) => service.post('/api/GridBased/page', params ) //编辑
export const GridBasedDetail = (params) => service.get('/api/GridBased/detail', {params:params} ) //详情
export const GridBasedDelete = (params) => service.post('/api/GridBased/delete', params ) //详情
export const GetGridBasedOrgName = (params) => service.get('/api/GridBased/GetGridBasedOrgName', {params:params} ) //详情

//党建

export const GetPartyBuildingList = (params) => service.get('/api/OpenAPI/GetPartyBuildingList', {params:params} ) //详情
//特大桥专项 BridgeSafety/add
export const BridgeSafetyAdd = (params) => service.post('/api/BridgeSafety/add', params ) //新增
export const BridgeSafetyDetail = (params) => service.get('/api/BridgeSafety/detail', {params:params} ) //详情
export const BridgeSafetyUpdate = (params) => service.post('/api/BridgeSafety/update', params ) //编辑
export const BridgeSafetyList = (params) => service.post('/api/BridgeSafety/page', params ) //编辑
export const BridgeSafetyDelete = (params) => service.post('/api/BridgeSafety/delete', params ) //编辑

//无人机 OpenAPI/GetVideoListByImportantPartUuid
export const GetVideoListByImportantPartUuid = (params) => service.get('/api/OpenAPI/GetVideoListByImportantPartUuid', {params:params} ) //详情
// 施工进度统计 MonthlyConstructionPlan/GetEngineeringProgressDesign?SGLX=WF
export const GetEngineeringProgressDesign = (params) => service.get('/api/MonthlyConstructionPlan/GetEngineeringProgressDesign', {params:params} ) //详情
//月计划 MonthlyConstructionPlan/GetEngineeringProgressPlan
export const GetEngineeringProgressPlan = (params) => service.get('/api/MonthlyConstructionPlan/GetEngineeringProgressPlan', {params:params} ) //详情
// 日进度
export const GetEngineeringProgressAnalysis = (params) => service.get('/api/MonthlyConstructionPlan/GetEngineeringProgressAnalysis', {params:params} ) //详情
export const GetEngineeringProgressFilling = (params) => service.get('/api/MonthlyConstructionPlan/GetEngineeringProgressFilling', {params:params} ) //详情
// DailyConstructionReport/GetFillingList
export const GetFillingList = (params) => service.post('/api/DailyConstructionReport/GetFillingList', params ) //详情
export const GetFillingLineChartList = (params) => service.post('/api/DailyConstructionReport/GetFillingLineChartList', params ) //详情
export const GetOverallSummary = (params) => service.get('/api/MonthlyConstructionPlan/GetOverallSummary',  {params:params} ) //MonthlyConstructionPlan/GetOverallSummary

export const ScheduleSummaryExport = (params) => service.post('/api/MonthlyConstructionPlan/ScheduleSummaryExport', params,{
} ) //导出

export const GetAuditCount = (params) => service.post('/api/ConstructionFilling/GetAuditCount', params ) //审核数量
export const ConstructionList = (params) => service.post('/api/ConstructionList/List', params ) //
export const ConstructionFillingAdd = (params) => service.post('/api/ConstructionFilling/add', params ) //
export const ConstructionFillingUpdate = (params) => service.post('/api/ConstructionFilling/update', params ) //
export const ConstructionFillingDelete = (params) => service.post('/api/ConstructionFilling/delete', params ) //
export const ConstructionFillingPage = (params) => service.post('/api/ConstructionFilling/page', params ) //
export const ConstructionFillingDetail= (params) => service.get('/api/ConstructionFilling/detail', {params:params} ) //
export const ConstructionFillingImport = (params) => service.post('/api/ConstructionFilling/Import',params) //
export const ConstructionFillingExport = (params) => service.post('/api/ConstructionFilling/Export',params) //
export const GrandBridgeScheduleSummaryExport = (params) => service.post('/api/MonthlyConstructionPlan/GrandBridgeScheduleSummaryExport',params) //
export const BuildingScheduleSummaryExport = (params) => service.post('/api/MonthlyConstructionPlan/BuildingScheduleSummaryExport',params) //
export const getUpDatePage = (params) => service.post('/api/sysDictData/page', params )
export const UpDateTime = (params) => service.post('/api/sysDictData/update', params )
export const GetDataCount = (params) => service.post('/api/ConstructionFilling/GetDataCount', params )

export const ScheduleDevelopment = (params) => service.post('/api/ScheduleDevelopment/page', params ) //进度表page
export const GetImportBatchList = (params) => service.post('/api/ScheduleDevelopment/GetImportBatchList', params ) //进度批次
export const ScheduleImport = (params) => service.post('/api/ScheduleDevelopment/Import', params ) //导入
export const ScheduleAdd = (params) => service.post('/api/ScheduleDevelopment/add', params ) //新增
export const ScheduleUpdate = (params) => service.post('/api/ScheduleDevelopment/update', params ) //编辑

//计量 /Metering/Import
export const MeteringPage = (params) => service.post('/api/Metering/page', params ) //进度表page
export const MeteringList = (params) => service.post('/api/Metering/GetImportBatchList', params ) //进度批次
export const MeteringImport = (params) => service.post('/api/Metering/Import', params ) //导入
export const MeteringAdd = (params) => service.post('/api/Metering/add', params ) //新增
export const MeteringUpdate = (params) => service.post('/api/Metering/update', params ) //编辑
export const GetMeteringStat = (params) => service.post('/api/OpenAPI/GetMeteringStat', params ) //编辑

export const ImportCYHG = (params) => service.post('/api/QualityControl/ImportCYHG', params ) //导入抽样表

//接收通知
export const GetPageReceived = (params) => service.get('/api/Notice/GetPageReceived', {params:params} ) //
export const SetRead = (params) => service.post('/api/Notice/SetRead', params ) //
export const UnReadList = (params) => service.get('/api/Notice/UnReadList', {params:params} ) //
//分析
export const LaboratoryRebarList = (params) => service.post('/api/LaboratoryRebar/page', params ) //
export const LaboratoryRebarImport = (params) => service.post('/api/LaboratoryRebar/Import', params ) //
export const GetLaboratoryRebarScatterChartData = (params) => service.get('/api/OpenAPI/GetLaboratoryRebarScatterChartData',  {params:params}  ) //

//传感器 OpenAPI/GetMbyProjectList
export const GetMbyProjectList = (params) => service.get('/api/OpenAPI/GetMbyProjectList', {params:params} ) //
export const GetMbyMonitoringPointList = (params) => service.get('/api/OpenAPI/GetMbyMonitoringPointList', {params:params} ) //
export const GetMbyPointDataPage = (params) => service.get('/api/OpenAPI/GetMbyPointDataPage', {params:params} ) //


//梁场/api/BeamYard/Page
export const BeamYardPage = (params) => service.post('/api/BeamYard/Page', params ) //
export const BeamYardAdd = (params) => service.post('/api/BeamYard/Add', params ) //
export const BeamYardUpdate = (params) => service.post('/api/BeamYard/Update', params ) //
export const BeamYardDetail = (params) => service.get('/api/BeamYard/Detail', {params:params} ) //
export const BeamYardDelete = (params) => service.post('/api/BeamYard/Delete', params ) //
export const BeamYardList = (params) => service.get('/api/BeamYard/List', {params:params} ) //

//梁场计划
export const BeamYardPlanPage = (params) => service.post('/api/BeamYardPlan/Page', params ) //
export const BeamYardPlanAdd = (params) => service.post('/api/BeamYardPlan/Add', params ) //
export const BeamYardPlanUpdate = (params) => service.post('/api/BeamYardPlan/Update', params ) //
export const BeamYardPlanDetail = (params) => service.get('/api/BeamYardPlan/Detail', {params:params} ) //
export const BeamYardPlanDelete = (params) => service.post('/api/BeamYardPlan/Delete', params ) //

//梁场进度
export const BeamYardProgressPage = (params) => service.post('/api/BeamYardProgress/Page', params ) //
export const BeamYardProgressAdd = (params) => service.post('/api/BeamYardProgress/Add', params ) //
export const BeamYardProgressUpdate = (params) => service.post('/api/BeamYardProgress/Update', params ) //
export const BeamYardProgressDetail = (params) => service.get('/api/BeamYardProgress/Detail', {params:params} ) //
export const BeamYardProgressDelete = (params) => service.post('/api/BeamYardProgress/Delete', params ) //

//应急管理BeamYardTask
export const BeamYardTaskPage = (params) => service.post('/api/BeamYardTask/Page', params ) //
export const BeamYardTaskAdd = (params) => service.post('/api/BeamYardTask/Add', params ) //
export const BeamYardTaskUpdate = (params) => service.post('/api/BeamYardTask/Update', params ) //
export const BeamYardTaskDetail = (params) => service.get('/api/BeamYardTask/Detail', {params:params} ) //
export const BeamYardTaskDelete = (params) => service.post('/api/BeamYardTask/Delete', params ) //
export const BeamYardTaskList = (params) => service.get('/api/BeamYardTask/List', {params:params} ) //
//应急任务上报
export const BeamYardTaskProgressPage = (params) => service.post('/api/BeamYardTaskProgress/Page', params ) //
export const BeamYardTaskProgressAdd = (params) => service.post('/api/BeamYardTaskProgress/Add', params ) //
export const BeamYardTaskProgressUpdate = (params) => service.post('/api/BeamYardTaskProgress/Update', params ) //
export const BeamYardTaskProgressDetail = (params) => service.get('/api/BeamYardTaskProgress/Detail', {params:params} ) //
export const BeamYardTaskProgressDelete = (params) => service.post('/api/BeamYardTaskProgress/Delete', params ) //

    //房建施工清单
export const GetFJConstructionListList = (params) => service.post('/api/ConstructionList/GetFJConstructionListList', params ) //
export default { recApiGet, recApiPost }


// export const testGetMethod = () => service.get('/test/get')
// export const testGetHaveParamsMethod =  (params) => service.get('/test/getHaveParams', { params: params })
// export const testPostJson =  (params) => service.post('/test/postJson', params)
// export const testPost = (params) =>
//   service.post('/test/post',
//     params,
//     {
//       headers: { "Content-Type": "application/x-www-form-urlencoded" }
//     }
//   )

